import { ActionIcon, Flex } from "@mantine/core";
import { MasterApiPendingReasonsPendingReasonIdDeleteRequest, PendingReasonsGet200ResponseRowsInner, TicketsGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutatePendingReasons } from "api/pending/usePendingReasonsQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseTable from "components/Table/Atom/BaseTable";
import { convertFilterToKorean } from "constants/receptionStatusList";
import { Column, SortColumn, SortDirection } from "react-data-grid";
import ReasonEditForm from "./reasonEditForm";

export interface SortProps {
  columnKey: string;
  direction: SortDirection | undefined
}

interface PendingReasonsTableProps {
  pendingReasons: PendingReasonsGet200ResponseRowsInner[];
  sortColumns: Map<string, SortProps>
  setSortColumns: React.Dispatch<React.SetStateAction<Map<string, SortProps>>>
  selectedRows: ReadonlySet<any>
  setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<any>>>
}
function PendingReasonsTable(params: PendingReasonsTableProps) {

  const { pendingReasons, sortColumns, setSortColumns, selectedRows, setSelectedRows } = params;
  const { openModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate : mutatePendingReasonsIdDelete } = useMutation(
    (params : MasterApiPendingReasonsPendingReasonIdDeleteRequest) => 
      mutatePendingReasons.delete(params).mutationFn(params as MasterApiPendingReasonsPendingReasonIdDeleteRequest | any), 
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["pendingReasons"]);
      }
    }
  )
  
  const columns: readonly Column<PendingReasonsGet200ResponseRowsInner>[] = [

    {
      key: "pendingReason",
      name: "지연단계",
      sortable: true,
      resizable: true,
      width: 280
    },
    {
      key: "name",
      name: "지연사유",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "옵션",
      width: 120,
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {


        const deleteReceiptAction = async (tid: number) => {
          if (window.confirm("지연사유를 삭제하시겠습니까?")) {
            deleteItem(tid);
          }
        };

        // delete item after confirm
        const deleteItem = async (pendingReasonId: number) => {
          mutatePendingReasonsIdDelete({
            pendingReasonId: pendingReasonId,
          });
        };

        return (
          <Flex gap={5}>
            <ActionIcon bg="white" variant="outline" onClick={() => openModal(<ReasonEditForm formatterProps={formatterProps} />, '', '지연사유 수정')}>
              <IconEdit />
            </ActionIcon>
            <ActionIcon bg="white" variant="outline" onClick={() => {
              deleteReceiptAction(formatterProps?.row?.id);
            }}>
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];



  return (
    <BaseTable
      style={{
        height: '30rem',
      }}
      columns={columns}
      rows={pendingReasons?.map((row) => {
        return {
          ...row,
          pendingReason: convertFilterToKorean(row.pendingType),
          pendingType: row.pendingType
        }
      })}
      rowKeyGetter={(row: TicketsGet200ResponseRowsInner) => row.id}
      sortColumns={Array.from(sortColumns.values()) as SortColumn[]}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  )
};

export default PendingReasonsTable;


import { css } from "@emotion/css";
import { ActionIcon, Flex, Text } from "@mantine/core";
import { TicketsGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useQuery } from "@tanstack/react-query";
import { pendingReasons } from "api/pending/usePendingReasonsQuery";
import useTicketMutation from "api/tickets/useTicketStatusChange";
import { Confirm } from "components/Confirm";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseTable from "components/Table/Atom/BaseTable";
import { stepStatusDisplay } from "constants/receptionStatusList";
import dayjs from "dayjs";
import { icons } from "modules/icons";
import { Column, SelectColumn, SortColumn, SortDirection } from "react-data-grid";
import { DetailInfoHorizon } from "./DetailInfoHorizon";
import EditForm from "./EditForm";

export interface SortProps {
  columnKey: string;
  direction: SortDirection | undefined

}

interface ReceptionTableTestProps {
  tickets: TicketsGet200ResponseRowsInner[];
  sortColumns: Map<string, SortProps>
  setSortColumns: React.Dispatch<React.SetStateAction<Map<string, SortProps>>>
  selectedRows: ReadonlySet<any>
  setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<any>>>
}
function ReceptionTable(params: ReceptionTableTestProps) {

  const { tickets, sortColumns, selectedRows, setSortColumns, setSelectedRows } = params

  const acceptPendingReason = useQuery(pendingReasons.get({
    query: [JSON.stringify({
      "$and": {
        "pendingType": "ACCEPT_PENDING",
        "$or": [
          {
            "siteId": {
              "$eq": localStorage.getItem('siteId')
            }
          },
          {
            "siteId": {
              "$is": null
            }
          }
        ]
      }
    })]
  }))

  const { resumeMutate, deleteMutate } = useTicketMutation();

  const { openModal, closeModal } = useModal()

  const handleSort = ({ columnKey, direction }: SortProps) => {

    if (direction === "ASC") {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: undefined
        })
      )
    } else if (direction === "DESC") {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: "ASC"
        })
      )
    } else {
      setSortColumns((prev) =>
        new Map(prev).set(columnKey, {
          columnKey,
          direction: "DESC"
        })
      )
    }
  }

  const columns: readonly Column<TicketsGet200ResponseRowsInner>[] = [
    {
      ...SelectColumn,
      width: 50,
      maxWidth: 500,
      resizable: true,
      frozen: false,
      headerCellClass: css`
            & > * {
              justify-content: flex-start;
              padding-left: 24px;
            }
          `,
      cellClass: css`
            .rdg-checkbox-label {
              padding-left: 24px;
            }
          `,
    },
    {
      key: "incomingAt",
      name: "입고일시",
      width: 180,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
      cellClass: css`
            color: #dde1e8;
            font-size: 14px;
            font-family: MBK CorpoS;
            font-weight: 700;
            word-wrap: break-word;
          `,
      headerRenderer: (props) => {
        return (
          <Flex
            w={80}
            align={'center'}
            style={{ cursor: "pointer" }}
            onClick={() => handleSort({ columnKey: props.column.key, direction: props.sortDirection })}>
            {props.column.name}
            {props.sortDirection === "ASC" && <icons.ArrowUpIcon />}
            {props.sortDirection === "DESC" && <icons.ArrowDownIcons />}
            {!props.sortDirection && (
              <Flex direction="column" align={'center'} justify={'center'}>
                <div style={{
                  height: "10px",
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowUpIcon />
                </div>
                <div style={{
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowDownIcons />
                </div>
              </Flex>
            )}
          </Flex>
        );
      },

    },
    {
      key: "plateCode",
      name: "차량번호",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
    },
    {
      key: "carModel",
      name: "모델명",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
    },
    {
      key: "customerName",
      name: "고객명",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
      headerRenderer: (props) => {
        return (
          <Flex
            w={80}
            align={'center'}
            style={{ cursor: "pointer" }}
            onClick={() => handleSort({ columnKey: props.column.key, direction: props.sortDirection })}>
            {props.column.name}
            {props.sortDirection === "ASC" && <icons.ArrowUpIcon />}
            {props.sortDirection === "DESC" && <icons.ArrowDownIcons />}
            {!props.sortDirection && (
              <Flex direction="column" align={'center'} justify={'center'}>
                <div style={{
                  height: "10px",
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowUpIcon />
                </div>
                <div style={{
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowDownIcons />
                </div>
              </Flex>
            )}
          </Flex>
        );
      },
    },
    {
      key: "mobile",
      name: "고객 연락처",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
    },
    {
      key: "createUserName",
      name: "접수자",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
      formatter: (formatterProps) => {
        return (
          <Text>{formatterProps.row.createUser?.name}</Text>
        )
      }
    },
    {
      key: "assignedTechnician",
      name: "담당 테크니션",
      sortable: true,
      resizable: true,
      formatter: (formatterProps) => {
        return (
          <Text>{formatterProps.row.assignedTechnician?.name}</Text>
        )
      }
    },
    {
      key: "stepStatus",
      name: "진행상태",
      sortable: true,
      resizable: true,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
      headerRenderer: (props) => {
        return (
          <Flex
            w={80}
            align={'center'}
            style={{ cursor: "pointer" }}
            onClick={() => handleSort({ columnKey: props.column.key, direction: props.sortDirection })}>
            {props.column.name}
            {props.sortDirection === "ASC" && <icons.ArrowUpIcon />}
            {props.sortDirection === "DESC" && <icons.ArrowDownIcons />}
            {!props.sortDirection && (
              <Flex direction="column" align={'center'} justify={'center'}>
                <div style={{
                  height: "10px",
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowUpIcon />
                </div>
                <div style={{
                  cursor: "pointer",
                  display: "flex",
                }}>
                  <icons.ArrowDownIcons />
                </div>
              </Flex>
            )}
          </Flex>
        );
      },
      formatter: (formatterProps) => {

        // const pending = acceptPendingReason.data?.data?.rows as PendingReasonsGet200ResponseRowsInner[]

        return (
          <Flex direction={'column'} gap={5} justify={'center'} align={'center'}>
            <Text>{stepStatusDisplay(formatterProps.row.stepStatus)}</Text>
            {/* {
              formatterProps.row.stepStatus === 'RECEIPTED' &&
              <Button
                bg={'red'}
                size="xs"
                onClick={
                  () => openModal(<ReceptionsCompleteModal formatterProps={formatterProps.row} pendingReasons={pending} />, '', '접수완료 지연 메세지 전송')}>
                정비전지연
              </Button>
            } */}
            {/* {
              formatterProps?.row?.stepStatus === 'ACCEPT_PENDING' &&
              <Button
                size="xs"
                color={"teal"}
                onClick={() => resumeMutate({
                  ticketId: formatterProps?.row?.id
                }, {
                  onSuccess: () => {
                    alert("접수지연이 해소되었습니다.")
                  }
                })}
              >{'접수완료 지연해소'}</Button >
            } */}
          </Flex>
        )
      }
    },
    {
      key: "options",
      name: "상세조회",
      sortable: true,
      resizable: true,
      width: 120,
      headerCellClass: css`
            & > * {
              font-weight: 900;
            }
          `,
      formatter: (formatterProps) => {
        // confimation for delete item

        const deleteReceiptAction = async (tid: number) => {
          deleteMutate({
            ticketId: tid,
          });
        };

        return (
          <Flex gap={5}>
            <ActionIcon variant="outline"
              bg='white'
              onClick={() => {
                openModal(<DetailInfoHorizon ticketId={formatterProps.row.id} />, null, '')
              }}
            >
              <Text fz={12}>조회</Text>
            </ActionIcon>

            <ActionIcon variant="outline"
              bg='white'
              onClick={() => {
                openModal(
                  <EditForm
                    formatterProps={formatterProps}
                  />,
                  "",
                  "접수 수정"
                );
              }}>
              <IconEdit />
            </ActionIcon>
            <ActionIcon variant="outline"
              bg='white'
              onClick={() => {
                openModal(
                  <Confirm
                    message="해당 접수건을 삭제하시겠습니까?"
                    yesCallback={() => deleteReceiptAction(formatterProps.row.id)}
                    noCallback={() => { }}
                    commonCallback={() => closeModal({})}
                  />,
                  null,
                  '삭제 확인'
                )

              }}
            >
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];

  return (
    <BaseTable
      style={{
        height: '30rem',
      }}
      columns={columns}
      rows={tickets?.map((row) => {
        return {
          ...row,
          stageName: row?.pendingReason
            ? `지연(${row.pendingReason})`
            : row.stage?.name ?? "-",
          incomingAt: row?.incomingAt ? (dayjs(row?.incomingAt).isValid() ? dayjs(row?.incomingAt).format("YYYY-MM-DD A hh:mm") : "Invalid Date") : "",
          createUserName: `${row?.createUser?.name} (${row?.createUser?.mobile ?? '-'})`,
        }
      })}
      rowKeyGetter={(row: TicketsGet200ResponseRowsInner) => row.id}
      sortColumns={Array.from(sortColumns.values()) as SortColumn[]}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  )
};

export default ReceptionTable;


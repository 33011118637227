import { css } from "@emotion/css";
import { ActionIcon, Flex } from "@mantine/core";
import { AuthSignupPost201Response, MasterApiUsersUserIdDeleteRequest, TicketsGet200ResponseRowsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { IconEdit, IconTrash } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { mutateUser } from "api/users/useUsersQuery";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import BaseTable from "components/Table/Atom/BaseTable";
import { Column, SortColumn, SortDirection } from "react-data-grid";
import { rolesList } from "utils/rolesTranslation";
import EditForm from "./EditForm";

export interface SortProps {
  columnKey: string;
  direction: SortDirection | undefined
}

interface UsersTableProps {
  users: AuthSignupPost201Response[];
  sortColumns: Map<string, SortProps>
  setSortColumns: React.Dispatch<React.SetStateAction<Map<string, SortProps>>>
  selectedRows: ReadonlySet<any>
  setSelectedRows: React.Dispatch<React.SetStateAction<ReadonlySet<any>>>
}
function ReceptionTable(params: UsersTableProps) {
  const { users, sortColumns, setSortColumns, selectedRows, setSelectedRows } = params;
  const { openModal } = useModal();

  const queryClient = useQueryClient();
  const { mutate: mutateUserIdDelete } = useMutation(
    (params: MasterApiUsersUserIdDeleteRequest) =>
      mutateUser.delete(params).mutationFn(params as MasterApiUsersUserIdDeleteRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["users"]);
      }
    }
  )



  const columns: readonly Column<AuthSignupPost201Response>[] = [
    {
      key: "email",
      name: "아이디",
      sortable: true,
      resizable: true,
      headerCellClass: css`
      justify-content: center;
    `,
      cellClass: css`
      justify-content: center;
    `,
    },
    {
      key: "name",
      name: "이름",
      sortable: true,
      resizable: true,
    },
    {
      key: "createdAt",
      name: "가입일자",
      resizable: true,
      cellClass: css`
        color: #dde1e8;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 700;
        word-wrap: break-word;
      `,
    },
    {
      key: "role",
      name: "포지션",
      sortable: true,
      resizable: true,
      formatter(formatterProps) {
        return <>{rolesList[formatterProps.row.role]}</>
      }
    },
    {
      key: "mobile",
      name: "핸드폰번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "exposedMobile",
      name: "노출 가능한 휴대폰 번호",
      sortable: true,
      resizable: true,
    },
    {
      key: "siteName",
      name: "센터",
      sortable: true,
      resizable: true,
    },
    {
      key: "options",
      name: "옵션",
      sortable: true,
      resizable: true,
      headerCellClass: css`
        justify-content: flex-start;
      `,
      cellClass: css`
        justify-content: flex-start;
      `,
      formatter(formatterProps) {
        const deleteReceiptAction = async (tid: number) => {
          if (window.confirm("해당 사용자를 삭제하시겠습니까?")) {
            mutateUserIdDelete({
              userId: tid,
            }, {
              onSuccess: () => {
                alert("삭제되었습니다.")
              }
            });
          }
        };
        return (
          <Flex gap={5}>
            <ActionIcon bg="white" variant="outline" onClick={() => {
              openModal(
                <EditForm
                  formatterProps={formatterProps}
                />,
                "",
                "사용자 정보 수정"
              );
            }}>
              <IconEdit />
            </ActionIcon>

            <ActionIcon bg="white" variant="outline" onClick={() => {
              deleteReceiptAction(formatterProps.row.id)
            }}>
              <IconTrash />
            </ActionIcon>
          </Flex>
        );
      },
    },
  ];


  return (
    <BaseTable
      style={{
        height: '30rem',
      }}
      columns={columns}
      rows={users?.map((user) => {
        return {
          id: user.id,
          email: user.email,
          role: user.role,
          siteId: user.siteId,
          name: user.name,
          roleName: user.role,
          mobile: user.mobile,
          workbayName: user.workbay?.name,
          workbayId: user.workbay?.id,
          siteName: user.site?.name,
          exposedMobile: user.exposedMobile,
          // createdAt: dayjs(user?.c).format("YY-MM-DD HH:mm"),
        }
      })}
      rowKeyGetter={(row: TicketsGet200ResponseRowsInner) => row.id}
      sortColumns={Array.from(sortColumns.values()) as SortColumn[]}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
    />
  )
};

export default ReceptionTable;


/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.07.30 (화)
 * 작성내용 : 입력 배열에서 undefined 값을 0으로 변환하고, 모든 요소를 숫자로 변환한다.
 *           배열 요소는 string 또는 undefined일 수 있다.
 * @param data (string | undefined)[] - 변환할 문자열 또는 undefined가 포함된 배열
 * @returns number[] - 변환된 숫자 배열
 */
export const convertArrayToNumbers = (data: (number | string | undefined)[]): number[] => {
    return data.map(item => item === undefined ? 0 : Number(item));
}

/**
 * 작성자명 : 원종석
 * 작성일자 : 2024.07.30 (화)
 * 작성내용 : 주어진 숫자 배열에서 최대값을 추출한다.
 * @param numbers number[] - 최대값을 찾을 숫자 배열
 * @returns number - 배열에서의 최대값
 */
export const extractMaxValue = (numbers: number[]): number => {
    return Math.max(...numbers);
}
import styled from "@emotion/styled";
import { Badge, Button, Checkbox, Divider, Flex, Table, Text, Title } from "@mantine/core";
import { PendingReasonsGet200ResponseRowsInner, TicketsGet200ResponseRowsInner, TicketsGet200ResponseRowsInnerStagesInfoInner, TicketsGet200ResponseRowsInnerStagesInfoInnerLogsInner } from "@sizlcorp/mbk-api-document/dist/models";
import { useQuery } from "@tanstack/react-query";
import { pendingReasons } from "api/pending/usePendingReasonsQuery";
import { tickets } from "api/tickets/useTicketsQuery";
import useTicketMutation from "api/tickets/useTicketStatusChange";
import { users } from "api/users/useUsersQuery";
import { Confirm } from "components/Confirm";
import { useModal } from "components/Modal/Atom/context/ModalStackManager";
import dayjs from "dayjs";
import { StepStatus } from "utils/stepStatus";
import DeliveryModal from "./DeliveryModal";
import FileForm from "./modal/file/FileForm";
import ReceptionsCompleteModal from "./ReceptionsCompleteModal";
import ShippingInfoModal, { deliveryTypeList } from "./ShippingInfoModal";

interface DetailInfoProps {
    ticketId: number;
}

export const DetailInfoHorizon = ({ ticketId }: DetailInfoProps) => {
    const { openModal, closeModal } = useModal();
    const { data: userInfo } = useQuery(users.who({}));
    const { data } = useQuery(tickets.detail(
        {
            ticketId: ticketId,
            populate: ["status", "stagesInfo", "createUser", "assignedAdviser", "assignedTechnician"]
        }
    ));
    const { startMutate, forceFinishMutate, forceCloseMutate, forceCloseCancelMutate, doneMutate, resumeMutate, updateMutate, deleteMutate, awaitReceiptMutate, temporaryStageInProgressMutate } = useTicketMutation();
    const loginUserId = userInfo?.data.id;
    const ticket = data?.data;
    const statusText = ticket?.stepStatus && StepStatus[ticket.stepStatus as keyof typeof StepStatus]; // 상태 한글화

    const deliveryPendingReason = useQuery(pendingReasons.get({
        query: [JSON.stringify({
            "$and": {
                "pendingType": "DELIVERY_PENDING",
                "$or": [
                    {
                        "siteId": {
                            "$eq": localStorage.getItem('siteId')
                        }
                    },
                    {
                        "siteId": {
                            "$is": null
                        }
                    }
                ]
            }
        })]
    }))

    const acceptPendingReason = useQuery(pendingReasons.get({
        query: [JSON.stringify({
            "$and": {
                "pendingType": "ACCEPT_PENDING",
                "$or": [
                    {
                        "siteId": {
                            "$eq": localStorage.getItem('siteId')
                        }
                    },
                    {
                        "siteId": {
                            "$is": null
                        }
                    }
                ]
            }
        })]
    }))

    // 비어 있지 않은 logs를 가진 마지막 stageInfo 찾기
    const lastStageWithLogs = ticket?.stagesInfo && ticket?.stagesInfo.slice().reverse().find(stage => stage.logs && stage.logs.length > 0);

    // 해당 stageInfo의 마지막 로그
    const finalLog = lastStageWithLogs?.logs && lastStageWithLogs?.logs[lastStageWithLogs.logs.length - 1];

    return (
        <Flex w="81rem" direction="column" gap="1rem">
            <Flex gap="md">
                <Flex w="40rem" direction="column" gap="md">
                    <Flex gap="md" align="center">

                        <Title order={2}>기본정보</Title>
                        {
                            ticket?.stepStatus === "CLOSED" &&
                            <Title order={3} color="red">본 접수건은 마감된 건입니다.</Title>
                        }
                    </Flex>

                    <Table withBorder withColumnBorders mah="20rem">
                        <tbody>
                            <tr>
                                <Td><Text fz="sm" fw={700}>차량번호</Text></Td>
                                <LabelTd>{ticket?.plateCode}</LabelTd>
                                <Td><Text fz="sm" fw={700}>모델명</Text></Td>
                                <LabelTd>{ticket?.carModel}</LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>VIN</Text></Td>
                                <LabelTd>{ticket?.vinCode}</LabelTd>
                                <Td><Text fz="sm" fw={700}>진행상태</Text></Td>
                                <LabelTd><Text fz="sm" fw={700} color="blue">{statusText}</Text></LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>고객명</Text></Td>
                                <LabelTd>{ticket?.customerName}</LabelTd>
                                <Td><Text fz="sm" fw={700}>고객 연락처</Text></Td>
                                <LabelTd>{ticket?.mobile}</LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>접수자명</Text></Td>
                                <LabelTd>{ticket?.createUser?.name}</LabelTd>
                                <Td><Text fz="sm" fw={700}>입고일시</Text></Td>
                                <LabelTd>{ticket?.incomingAt ? (dayjs(ticket?.incomingAt).isValid() ? dayjs(ticket?.incomingAt).format("YYYY-MM-DD A hh:mm") : "Invalid Date") : ""}</LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>담당 어드바이저</Text></Td>
                                <LabelTd>{ticket?.assignedAdviser?.name}</LabelTd>
                                <Td><Text fz="sm" fw={700}>담당 테크니션</Text></Td>
                                <LabelTd>{ticket?.assignedTechnician?.name}</LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>정비종료형태</Text></Td>
                                <LabelTd>
                                    {
                                        ticket?.status?.terminationType &&
                                        <Badge color={ticket?.status?.terminationType === "임의종료" ? "red" : ""}>
                                            {ticket?.status?.terminationType}
                                        </Badge>
                                    }
                                </LabelTd>
                                <Td><Text fz="sm" fw={700}>출고상태</Text></Td>
                                <LabelTd>
                                    <Flex gap="xs">
                                        <Badge color={ticket?.status?.deliveredType === "정상출고" ? "" : ticket?.status?.deliveredType === "임의출고" ? "red" : "gray"}>
                                            {ticket?.stepStatus === "DELIVERED" ? ticket?.status?.deliveredType : "미출고"}
                                        </Badge>
                                        {ticket?.shippingInfo?.type && <Badge>{deliveryTypeList.find((deliveryType) => deliveryType.value === ticket?.shippingInfo?.type)?.label}</Badge>}
                                    </Flex>
                                </LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>주차위치</Text></Td>
                                <LabelTd>{ticket?.parkingLocation}</LabelTd>
                                <Td><Text fz="sm" fw={700}>알림톡 사용</Text></Td>
                                <LabelTd>
                                    <Flex gap="lg">
                                        <Checkbox disabled checked={ticket?.usesNotification ?? false} label="사용" />
                                        <Checkbox disabled checked={!ticket?.usesNotification ?? false} label="미사용" />
                                    </Flex>
                                </LabelTd>
                            </tr>
                            <tr>
                                <Td><Text fz="sm" fw={700}>비고</Text></Td>
                                <td colSpan={3}>{ticket?.context}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Flex>
                <Flex w="40rem" direction="column" gap="md">
                    <Title order={2}>상세 정비 로그</Title>
                    <section style={{ maxHeight: "20rem", overflowY: 'auto' }}>
                        <Table withBorder withColumnBorders>
                            <thead>
                                <tr>
                                    <Th>진행단계</Th>
                                    <Th>시작시간</Th>
                                    <Th>담당자</Th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    ticket?.stagesInfo?.map((info: TicketsGet200ResponseRowsInnerStagesInfoInner) => {
                                        return info.logs && info?.logs.map((log: TicketsGet200ResponseRowsInnerStagesInfoInnerLogsInner) => {
                                            const isLastLog = log === finalLog; // 전체 로그 중 마지막 로그인지 확인
                                            const statusText = log.stepStatus && StepStatus[log.stepStatus as keyof typeof StepStatus]; // 상태 한글화
                                            const technicianName = log.user?.name ?? "-" // 담당 테크니션 이름

                                            return (
                                                <tr key={log.createdAt}
                                                    style={{
                                                        backgroundColor: isLastLog ? "#eaedf0" : ""
                                                    }}>
                                                    <td>
                                                        <Flex style={{ wordBreak: "break-all" }}>
                                                            <Text fw={isLastLog ? 700 : 500} c={isLastLog ? 'blue' : ''}>
                                                                {statusText}

                                                            </Text>
                                                            {/* 지연사유 or 상세정비 */}
                                                            {log?.additional?.pendingReason && <Text fw={isLastLog ? 700 : 500} c={isLastLog ? 'blue' : ''}>&nbsp;({log.additional.pendingReason.name})</Text>}
                                                            {log?.additional?.process && <Text fw={isLastLog ? 700 : 500} c={isLastLog ? 'blue' : ''}>({log.additional.process.name})</Text>}
                                                        </Flex>
                                                    </td>
                                                    <td>
                                                        <Text fw={isLastLog ? 700 : 500} fz="sm" color={isLastLog ? "blue" : ""}>{dayjs(log.createdAt).format("YYYY-MM-DD A hh:mm")}</Text>
                                                    </td>
                                                    <td>
                                                        <Text fw={isLastLog ? 700 : 500} c={isLastLog ? 'blue' : ''}>
                                                            {technicianName}
                                                        </Text>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    })
                                }
                                {
                                    !lastStageWithLogs && (
                                        <tr>
                                            <td colSpan={2}><Text ta="center">정비 로그가 존재하지 않습니다.</Text></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </section>
                </Flex>
            </Flex>
            <Divider />
            <Flex justify="space-between">
                <Flex justify="flex-start" gap="md">
                    <Button
                        onClick={() => {
                            openModal(
                                <FileForm
                                    ticketId={ticketId}
                                />,
                                null,
                                "첨부파일 업로드"
                            );
                        }}>
                        첨부파일
                    </Button>
                    {
                        ticket?.stepStatus === "TEMPORARY_RECEIPTED" && (
                            <Button
                                color="indigo.5"
                                onClick={() => {
                                    updateMutate({
                                        ticketId: ticket?.id,
                                        ticketsTicketIdDeleteRequest: {
                                            assignedAdviserId: loginUserId,
                                            incomingAt: new Date().toISOString(),
                                        }
                                    }, {
                                        onSuccess: () => {
                                            awaitReceiptMutate({
                                                ticketId: ticket?.id as number,
                                            }, {
                                                onSuccess: () => {
                                                    closeModal({});
                                                }
                                            })
                                        }
                                    })
                                }}>접수 완료</Button>
                        )
                    }
                    {
                        ticket?.stepStatus === "RECEIPTED" && (
                            <Button
                                color="blue.9"
                                onClick={() => {
                                    const pending = acceptPendingReason.data?.data.rows as PendingReasonsGet200ResponseRowsInner[]
                                    openModal(<ReceptionsCompleteModal
                                        formatterProps={ticket as TicketsGet200ResponseRowsInner}
                                        pendingReasons={pending} />,
                                        '',
                                        '접수완료 지연 메세지 전송');
                                }
                                }>정비 전 지연</Button>
                        )
                    }
                    {
                        ticket?.stepStatus === "ACCEPT_PENDING" && (
                            <Button
                                color="indigo.7"
                                onClick={() => {
                                    resumeMutate({
                                        ticketId: ticket?.id as number,
                                    })
                                }}>정비 전 지연 해소</Button>
                        )
                    }
                    {/* {
                        ticket?.stepStatus === "WAITING"
                        && <Button color="blue.3" onClick={() => {
                            updateMutate({
                                ticketId: ticket?.id,
                                ticketsTicketIdDeleteRequest: {
                                    assignedTechnicianId: loginUserId,
                                }
                            }, {
                                onSuccess: () => {
                                    startMutate({
                                        ticketId: ticket?.id as number,
                                    })
                                }
                            })
                        }}>정비 시작</Button>
                    } */}
                    {
                        ticket?.stepStatus === "WAITING"
                        && <Button color="blue.3" onClick={() => {
                            openModal(<Confirm
                                message={ticket.usesNotification ? "임시 정비시작시, 고객에게 알림톡이 전송됩니다." : ""}
                                yesCallback={() => {
                                    temporaryStageInProgressMutate({
                                        ticketId: ticket?.id as number,
                                    })
                                }}
                                noCallback={() => { }}
                                commonCallback={() => closeModal({})}
                            />, null, "임시 정비시작")
                        }}>임시 정비시작</Button>
                    }
                    {
                        (
                            finalLog?.stepStatus === "WAITING" ||
                            finalLog?.stepStatus === "STAGE_WAITING" ||
                            finalLog?.stepStatus === "STAGE_IN_PROGRESS" ||
                            finalLog?.stepStatus === "TEMPORARY_STAGE_IN_PROGRESS"
                        ) && <Button color="blue.8" onClick={() => {
                            forceFinishMutate({
                                ticketId: ticket?.id as number,
                                ticketsTicketIdForceFinishPutRequest: {
                                    message: "임의 정비 종료"
                                }
                            })
                        }}>임의 정비 종료</Button>
                    }
                    {
                        ticket?.stepStatus === "DONE" && (
                            <Button
                                color="indigo"
                                onClick={() => {
                                    const alreadySent = ticket?.stepStatus === "DONE_CONFIRMED"
                                        || ticket?.stepStatus === "DELIVERY_PENDING"
                                        || ticket?.stepStatus === "DELIVERED"

                                    if (window.confirm(alreadySent ? "이미 완료 메시지를 전송하였습니다. 다시 전송하시겠습니까?" : "완료 메시지를 전송하시겠습니까?")) {
                                        doneMutate({
                                            ticketId: ticket?.id as number,
                                        })
                                    }
                                }}
                            >
                                완료메시지 전송
                            </Button>
                        )
                    }
                    {
                        ticket?.stepStatus === "DONE_CONFIRMED" && (
                            <>
                                <Button
                                    color="indigo.3"
                                    onClick={() => {
                                        const pending = deliveryPendingReason?.data?.data?.rows as PendingReasonsGet200ResponseRowsInner[]
                                        openModal(
                                            <DeliveryModal
                                                pendingReasons={pending}
                                                formatterProps={ticket as TicketsGet200ResponseRowsInner} />,
                                            '',
                                            '출고 지연 메세지 전송'
                                        );
                                    }}>출고 지연</Button>
                                <Button
                                    color="indigo.8"
                                    onClick={() => {
                                        openModal(
                                            <ShippingInfoModal
                                                customerInfo={{
                                                    contact: ticket?.mobile
                                                }}
                                                formatterProps={ticket as TicketsGet200ResponseRowsInner}
                                            />,
                                            "",
                                            "출고 처리"
                                        );
                                    }}>출고 처리</Button>
                            </>
                        )
                    }
                    {
                        ticket?.stepStatus === "DELIVERY_PENDING" && (
                            <Button color="indigo.8" onClick={() => {
                                resumeMutate({
                                    ticketId: ticket?.id as number,
                                })
                            }}>출고 지연 해소</Button>
                        )
                    }
                </Flex>
                <Flex justify="flex-end" gap="md">
                    {
                        (
                            finalLog?.stepStatus === "RECEIPTED" ||
                            finalLog?.stepStatus === "WAITING" ||
                            finalLog?.stepStatus === "STAGE_WAITING" ||
                            finalLog?.stepStatus === "STAGE_IN_PROGRESS" ||
                            finalLog?.stepStatus === "PROCESSING" ||
                            finalLog?.stepStatus === "DONE" ||
                            finalLog?.stepStatus === "DONE_CONFIRMED"
                        ) && <Button color="red" onClick={() => {
                            forceCloseMutate({
                                ticketId: ticket?.id as number,
                                ticketsTicketIdClosedPutRequest: {
                                    message: "강제 마감"
                                }
                            })
                        }}>강제 마감</Button>
                    }
                    {
                        finalLog?.stepStatus === "CLOSED" && <Button color="violet" onClick={() => {
                            forceCloseCancelMutate({
                                ticketId: ticket?.id as number,
                                ticketsTicketIdCancelClosedAutoPutRequest: {
                                    message: "강제 마감 취소"
                                }
                            })
                        }}>강제 마감 취소</Button>
                    }
                    <Button color="red.9" onClick={() => {
                        openModal(
                            <Confirm
                                message="해당 접수건을 삭제하시겠습니까?"
                                yesCallback={() => deleteMutate({
                                    ticketId: ticket?.id as number
                                })}
                                noCallback={() => { }}
                                commonCallback={() => closeModal({})}
                            />, null, '삭제 확인'
                        ).then(() => { closeModal({}) })
                    }}>접수건 삭제</Button>
                </Flex>
            </Flex>
        </Flex>
    )
}

const Td = styled.td`
    vertical-align: middle;
    width: 16.2%;
    height: 2.5rem;
    background-color: #F8F9FA;
`

const LabelTd = styled.td`
    vertical-align: middle;
    width: 33.8%;
    height: 2.5rem;
`

const Th = styled.th`
    width: 50%;
`